import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import _ from 'lodash'
import React from 'react'
import { ReferenceInput, required, TextInput } from 'react-admin'
import { change } from 'redux-form'

import { VideoField } from '../../../../../components/field'
import { AddAssetInput } from '../../../../../components/inputs'
import { getResource } from '../../../../../config/resources'
import { NotificationStyled, VideoProviderContainerStyled } from './style'

const Radio = ({ form, dispatch, disabled, ...props }) => {
  const { value, onChange } = props.input
  const { choices } = props
  if (!value) onChange(choices[0])
  return (
    <AppBar position="static">
      <Tabs
        value={_.findIndex(choices, { id: value.id })}
        onChange={(event, newValue) => {
          const selectedSource = choices[newValue]
          if (selectedSource.slug === 'youtube' || selectedSource.slug === 'vimeo') {
            dispatch(change(form, 'providerId', null))
          } else {
            dispatch(change(form, 'providerId', selectedSource.slug))
          }
          onChange(selectedSource)
        }}
      >
        {_.map(choices, (item) => (
          <Tab disabled={disabled} label={item.name} />
        ))}
      </Tabs>
    </AppBar>
  )
}

const UploadVideo = ({ myFormData, myForm, dispatch, isPremium }) => {
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12}>
        <ReferenceInput
          label="Source"
          source="source"
          reference={getResource('assets', 'sources')}
          validate={[required()]}
          filter={{ isForPremium: isPremium }}
        >
          <Radio disabled={myFormData && !myFormData.id} form={myForm} dispatch={dispatch} isPremium={isPremium} />
        </ReferenceInput>
        {myFormData && !myFormData.id && (
          <VideoProviderContainerStyled>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <NotificationStyled>Save before video uploading video</NotificationStyled>
                <TextInput disabled style={{ display: 'none' }} defaultValue="default" fullWidth source="providerId" label="provider" validate={[required()]} />
              </Grid>
            </Grid>
          </VideoProviderContainerStyled>
        )}
        {myFormData && myFormData.id && (
          <VideoProviderContainerStyled>
            {myFormData && myFormData.source && myFormData.source.slug === 'youtube' && (
              <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                  <TextInput fullWidth source="providerId" label="Youtube Id" validate={[required()]} />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <iframe
                    title="youtube preview"
                    width="100%"
                    height="189"
                    src={`https://www.youtube.com/embed/${myFormData.providerId}`}
                    frameBorder="0"
                    allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </Grid>
              </Grid>
            )}
            {myFormData && myFormData.source && myFormData.source.slug === 'vimeo' && (
              <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                  <TextInput fullWidth source="providerId" label="Vimeo Id" validate={[required()]} />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <iframe
                    title="vimeo preview"
                    src={`https://player.vimeo.com/video/${myFormData.providerId}`}
                    width="100%"
                    height="189"
                    frameBorder="0"
                    allow="fullscreen"
                    allowFullScreen
                  ></iframe>
                </Grid>
              </Grid>
            )}
            {myFormData && myFormData.source && myFormData.source.slug === 'vod' && (
              <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                  <AddAssetInput
                    onChange={(data) => {
                      dispatch(change(myForm, 'providerId', data.fileDir))
                    }}
                    onChangeVideoOrientation={(data) => {
                      dispatch(change(myForm, 'orientation', data))
                    }}
                    isPremium={isPremium}
                    source="videoStorage[0]"
                    label="Video"
                    accept="video/*"
                    fullWidth
                    validate={[required()]}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <VideoField label="VOD Video" autorefresh record={myFormData} fullWidth source="videoStorage[0]" sourceId="id" />
                </Grid>
              </Grid>
            )}
            {myFormData && myFormData.source && myFormData.source.slug === 'overon' && (
              <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                  <TextInput fullWidth source="videoOveron[0].urlWeb" label="Overon url web" validate={[required()]} />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextInput fullWidth source="videoOveron[0].urlNative" label="Overon url native" validate={[required()]} />
                </Grid>
              </Grid>
            )}
            {myFormData && myFormData.source && myFormData.source.slug === 'url' && (
              <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                  <TextInput fullWidth source="providerId" label="url" validate={[required()]} />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <video width="400" controls>
                    <source key={myFormData.providerId} src={myFormData.providerId} type="video/mp4" />
                  </video>
                </Grid>
              </Grid>
            )}
            {myFormData && myFormData.source && myFormData.source.slug === 'ott' && (
              <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                  <TextInput fullWidth source="providerId" label="OTT id" validate={[required()]} />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  {myFormData.sourceUrl && (
                    <iframe title="ott preview" src={`${myFormData.sourceUrl}?H=${process.env.REACT_APP_OTT_CLIENT_ID}`} width="100%" height="225"></iframe>
                  )}
                </Grid>
              </Grid>
            )}
          </VideoProviderContainerStyled>
        )}
      </Grid>
    </Grid>
  )
}

export default UploadVideo
