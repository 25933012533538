import React from 'react'
import CompetitionsList from './sections/competitions'
import MobileAppsList from './sections/mobileapps'
import Rrss from './sections/rrss'
import HomeState from './sections/homestate'
import GuestMode from './sections/guestmode'
import AdsSpacesList from './sections/adsspaces'
import TransfersConfig from './sections/transfersConfig'

class AppManagement extends React.Component {
  render() {
    return (
      <>
        <CompetitionsList />
        <MobileAppsList />
        <Rrss />
        <HomeState />
        <GuestMode />
        <AdsSpacesList />
        <TransfersConfig />
      </>
    )
  }
}

export default AppManagement
