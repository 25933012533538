import React from 'react'
import { connect } from 'react-redux'
import { GET_LIST, showNotification, UPDATE } from 'react-admin'

import { Typography, CircularProgress, Button, Grid, Collapse, FormGroup, FormControlLabel, Switch, TextField } from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

import dataProvider from '../../../../../providers'
import { getResource } from '../../../../../config/resources'

class TransfersConfig extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openList: false,
      homestate: null,
      loading: false,
      fetching: false,
    }
  }

  componentDidMount() {
    this.getHomestate()
  }

  getHomestate = () => {
    this.setState({ loading: true })
    dataProvider(GET_LIST, getResource('transversal', 'globaldatas'), {
      sort: {
        field: 'id',
        order: 'DESC',
      },
      filter: {
        name: 'APP_CONFIG',
      },
      pagination: { page: 1, perPage: 20 },
    }).then((res) => {
      this.setState({ homestate: res.data[0], loading: false })
    })
  }

  handleClick = () => {
    this.setState({
      openList: !this.state.openList,
    })
  }

  handleTransfersConfig(event, section) {
    const copyHomeState = { ...this.state.homestate }
    if (section === 'transferConfig') {
      copyHomeState.contentJson.transferConfig.active = event.target.checked
    } else if (section === 'start' || section === 'end') {
      if (!event.target.value) {
        return
      }
      copyHomeState.contentJson.transferConfig[section] = event.target.value
    }

    this.setState({ homestate: copyHomeState })
  }

  handleSaveData() {
    this.setState({ fetching: true })
    let data = { ...this.state.homestate }
    data.contentJson = JSON.stringify(data.contentJson)
    dataProvider(UPDATE, getResource('transversal', 'globaldatas'), {
      id: this.state.homestate.id,
      data: data,
    })
      .then(({ data }) => {
        this.setState({ fetching: false, homestate: data })
        this.props.showNotification('Transfers config state updated', 'success')
      })
      .catch((error) => {
        this.setState({ error: true, fetching: false })
        this.props.showNotification(error.message, 'error')
      })
  }

  getToday = () => {
    const today = new Date()
    const yyyy = today.getFullYear()
    const mm = String(today.getMonth() + 1).padStart(2, '0')
    const dd = String(today.getDate()).padStart(2, '0')
    return `${yyyy}-${mm}-${dd}`
  }

  isEndDateValid = (startDate, endDate) => {
    const start = new Date(startDate)
    const end = new Date(endDate)

    return end >= start
  }

  render() {
    const startDate = this.state.homestate?.contentJson?.transferConfig?.start
    const endDate = this.state.homestate?.contentJson?.transferConfig?.end
    return (
      <>
        <div style={{ margin: '29px' }}>
          {this.state.loading ? (
            <div style={{ display: 'flex' }}>
              <Typography component="h2" variant="h2" style={{ marginBottom: '16px', marginRight: '16px' }}>
                Loading Transfers Config
              </Typography>
              <CircularProgress size={24} />
            </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'flex-start' }} onClick={() => this.handleClick()}>
              <Typography component="h2" variant="h2" style={{ marginBottom: '16px' }}>
                Transfers Config
              </Typography>
              <div style={{ marginLeft: '16px' }}>{this.state.openList ? <ExpandLess /> : <ExpandMore />}</div>
            </div>
          )}
          {this.state.homestate && this.state.openList && (
            <Collapse in={this.state.openList} timeout={350}>
              <Grid item xs={12} lg={8}>
                <div style={{ opacity: this.state.loading ? 0 : 1, transition: 'opacity 350ms' }}>
                  <Grid item xs={12} lg={8}>
                    <Grid item xs={12}>
                      <FormGroup>
                        <FormControlLabel
                          label="Active"
                          control={
                            <Switch
                              checked={this.state.homestate?.contentJson?.transferConfig?.active}
                              value={this.state.homestate?.contentJson?.transferConfig?.active}
                              onChange={(event) => this.handleTransfersConfig(event, 'transferConfig')}
                              name="active"
                              color="primary"
                            />
                          }
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Start Date"
                        type="date"
                        name="start"
                        value={this.state.homestate?.contentJson?.transferConfig?.start}
                        inputProps={{
                          min: this.getToday(),
                        }}
                        onChange={(event) => this.handleTransfersConfig(event, 'start')}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        label="End Date"
                        type="date"
                        name="end"
                        value={this.state.homestate?.contentJson?.transferConfig?.end}
                        inputProps={{
                          min: this.state.homestate?.contentJson?.transferConfig?.start,
                        }}
                        onChange={(event) => this.handleTransfersConfig(event, 'end')}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {!this.isEndDateValid(startDate, endDate) && <Typography>The end date cannot be lower than the start date.</Typography>}
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      disabled={this.state.loading || this.state.fetching || !this.isEndDateValid(startDate, endDate)}
                      onClick={() => this.handleSaveData()}
                    >
                      {this.state.fetching ? 'Saving...' : 'Save'}
                    </Button>
                  </Grid>
                </div>
              </Grid>
            </Collapse>
          )}
        </div>
      </>
    )
  }
}

const enhance = connect(null, {
  showNotification,
})

export default enhance(TransfersConfig)
